<template>
  <TabView>
    <TabPanel v-for="tab in tabs" :key="tab.name">
      <template #header>
        <i :class="tab.icon"></i>
        <span>{{ tab.name }}</span>
      </template>
      <slot :name="tab.slot"></slot>
    </TabPanel>
  </TabView>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
};
</script>
