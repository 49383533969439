<template>
  <div class="companies">
    <DefaultTabPanel
      v-model:activeIndex="message.activeTab"
      :tabs="message.tabs"
      @tab-change="onChangeTab"
    >
      <template v-slot:inbox>
        <!-- <MobileTable
          v-model:selection="store.state.message.selectedInbox"
          label="Mensajes recibidos"
          :columns="message.columnsInbox"
          :data="store.state.message.inbox"
        /> -->
        <DesktopGroupTable
          v-model:selection="store.state.message.selectedInbox"
          label="Mensajes recibidos"
          :data="store.state.message.inbox"
          :onSelect="onSelectMessage"
          :inbox="true"
        />
        <Actions :items="message.actionsInbox" />
      </template>

      <template v-slot:send>
        <!-- <MobileTable
          v-model:selection="store.state.message.selectedSend"
          label="Mensajes enviados"
          :columns="message.columnsSend"
          :data="store.state.message.send"
        /> -->
        <DesktopGroupTable
          v-model:selection="store.state.message.selectedSend"
          label="Mensajes enviados"
          :data="store.state.message.send"
          :onSelect="onSelectMessage"
        />
        <Actions :items="message.actionsSend" />
      </template>
    </DefaultTabPanel>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import DefaultTabPanel from "../../widgets/tabpanel/DefaultTabPanel.vue";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopGroupTable from "../../widgets/tables/DesktopGroupTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: { DefaultTabPanel, MobileTable, DesktopGroupTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/mensajes/nuevo");
    };
    const onSend = async () => {
      if (store.state.message.selectedSend.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else if (
        store.state.message.selectedSend[0].message.messageStatusId !== 1
      ) {
        store.commit("setSelectedSend", []);
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Este mensaje ya fue enviado!",
          life: 5000,
        });
      } else {
        store.commit("setLoading", true);
        const response = await store.dispatch(
          actions.messageActions.sendMessage,
          {
            messageId: store.state.message.selectedSend[0].message.id,
            messageStatusId: 2,
          }
        );
        if (response.ok) {
          await store.dispatch(actions.messageActions.send, {
            buildingId: store.state.general.selectedBuilding,
            userId: store.state.auth.user.id,
          });
          store.commit("setSelectedSend", []);
          store.commit("setLoading", false);
        } else {
          store.commit("setLoading", false);
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
        }
      }
    };
    const onChat = async () => {
      if (
        (message.activeTab === 0 &&
          store.state.message.selectedInbox.length !== 1) ||
        (message.activeTab === 1 &&
          store.state.message.selectedSend.length !== 1)
      ) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else if (message.activeTab === 0) {
        const response = await store.dispatch(
          actions.messageActions.chageStatus,
          {
            userId: store.state.auth.user.id,
            messageId: store.state.message.selectedInbox[0].message.id,
          }
        );

        if (response.ok) {
          router.push("/mensajes/chat");
          store.commit("setIsSend", message.activeTab === 1 ? true : false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
        }
      } else {
        router.push("/mensajes/chat");
        store.commit("setIsSend", message.activeTab === 1 ? true : false);
      }
    };

    const message = reactive({
      tabs: [
        { icon: "pi pi-inbox", name: "Recibidos", slot: "inbox" },
        { icon: "pi pi-send", name: "Enviados", slot: "send" },
      ],
      activeTab: 0,
      actionsInbox: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/mensajes/nuevo"),
        },
        {
          name: "Chat",
          action: onChat,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/mensajes/chat"),
        },
      ],
      actionsSend: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/mensajes/nuevo"),
        },
        {
          name: "Enviar",
          action: onSend,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/mensajes/nuevo"),
        },
        {
          name: "Chat",
          action: onChat,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/mensajes/chat"),
        },
      ],
    });

    const onChangeTab = async (tab) => {
      store.commit("setLoading", true);
      store.commit("setSelectedInbox", []);
      store.commit("setSelectedSend", []);
      if (tab.index === 0) {
        await store.dispatch(actions.messageActions.inbox, {
          buildingId: store.state.general.selectedBuilding,
          userId: store.state.auth.user.id,
        });
      } else {
        await store.dispatch(actions.messageActions.send, {
          buildingId: store.state.general.selectedBuilding,
          userId: store.state.auth.user.id,
        });
      }
      store.commit("setLoading", false);
    };

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedInbox", []);
      store.commit("setSelectedSend", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(actions.messageActions.inbox, {
        buildingId: store.state.general.selectedBuilding,
        userId: store.state.auth.user.id,
      });
      await store.dispatch("NOTIFICATION_DELETE", {
        userId: store.state.auth.user.id,
        notificationTypeId: 3,
      });
      store.commit("setLoading", false);
    });

    const onSelectMessage = async (data) => {
      if (message.activeTab === 0)
        await store.commit("setSelectedInbox", [data]);
      else await store.commit("setSelectedSend", [data]);
      onChat();
    };

    return { store, message, onChangeTab, onSelectMessage };
  },
};
</script>

<style scoped></style>
