<template>
  <DataTable
    :value="data"
    responsiveLayout="scroll"
    breakpoint="960px"
    dataKey="id"
    :paginator="true"
    :rows="rows"
    class="container-desktop"
    rowGroupMode="subheader"
    :groupRowsBy="group"
    sortMode="single"
    sortField="order"
    :sortOrder="-1"
    :expandableRowGroups="true"
    v-model:expandedRowGroups="expandedRowGroups"
  >
    <template #header v-if="label">
      {{ label }}
    </template>
    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
    <Column
      field="message.type.name"
      header="Categoría"
      class="center"
      headerStyle="min-width: 6rem"
    >
      <template #body="slotProps">
        <div class="no-read-container">
          <div
            v-if="slotProps.data.messageStatusId === 3 && inbox"
            class="no-read"
          ></div>
          <div v-else class="read"></div>
          <strong class="name-strong" @click="onSelect(slotProps.data)">
            {{ slotProps.data.message.type.name }}</strong
          >
        </div>
      </template>
    </Column>
    <Column
      field="message.tower"
      header="Torre"
      class="center"
      headerStyle="min-width: 6rem"
    >
      <template #body="slotProps">
        {{ slotProps.data.message.tower }}
      </template>
    </Column>
    <Column
      field="message.apartment"
      header="Apartamento"
      class="center"
      headerStyle="min-width: 6rem"
    >
      <template #body="slotProps">
        {{ slotProps.data.message.apartment }}
      </template>
    </Column>
    <Column
      field="name"
      header="Usuario"
      class="center"
      headerStyle="min-width: 6rem"
    >
      <template #body="slotProps">
        {{ slotProps.data.name }}
      </template>
    </Column>
    <Column
      field="message.createdAt"
      header="Fecha"
      class="center"
      headerStyle="min-width: 6rem"
    >
      <template #body="slotProps">
        {{
          `${dates.dateToString(
            slotProps.data.message.createdAt
          )} ${dates.timeToString(slotProps.data.message.createdAt)}`
        }}
      </template>
    </Column>
    <Column
      field="message.status.name"
      header="Estado"
      class="center"
      headerStyle="min-width: 6rem"
    >
      <template #body="slotProps">
        {{ slotProps.data.message.status.name }}
      </template>
    </Column>
    <template #groupheader="slotProps">
      <div
        v-if="slotProps.data.messageStatusId === 3 && inbox"
        class="parent-no-read"
      ></div>
      <div v-else class="parent-read"></div>
      <span>{{ slotProps.data.message.title }}</span>
    </template>
  </DataTable>
</template>

<script>
import { ref } from "vue";
import { currency, dates } from "../../constants";

export default {
  props: {
    rows: {
      type: Number,
      default: 10,
    },
    columns: {
      type: Array,
      default: [],
    },
    data: {
      type: Array,
      default: [],
    },
    label: {
      type: String,
      default: "",
    },
    group: {
      type: String,
      default: "message.messageParentId",
    },
    onSelect: {
      type: Function,
      default: (row) => console.log(row),
    },
    inbox: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const expandedRowGroups = ref();
    return { currency, dates, expandedRowGroups };
  },
};
</script>

<style scoped>
.container-desktop {
  margin: 0 4rem;
  max-width: calc(100vw - 8rem);
  display: none;
}
span {
  color: var(--primary);
  font-weight: bold;
  margin-left: 1rem;
}
.name-strong {
  cursor: pointer;
  color: var(--primary-dark);
}
.name-strong:hover {
  text-decoration: underline;
}
.no-read-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.no-read {
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--primary-dark);
  border-radius: 50%;
  margin-right: 1rem;
}
.read {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 1rem;
}
.parent-no-read {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--primary-dark);
  border-radius: 50%;
}
.parent-read {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}
@media screen and (min-width: 1440px) {
  .container-desktop {
    display: block;
  }
}
</style>
